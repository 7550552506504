import Link from 'next/link';

import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { GlobalButtonFinal, ModalsWrapper, ModalWindow } from '../GlobalStyles';
import { signOut, useSession } from 'next-auth/client';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import ShareDialog from './ShareDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import * as Sentry from '@sentry/nextjs';
import {
    faBars,
    faBell,
    faComments,
    faLock,
    faLanguage,
    faPlus,
    faUnlock,
    faUser,
    faUsers,
    faRightFromBracket,
    faUp,
    faCoins,
    faCamera,
    faArrowRightArrowLeft,
    faQuestionCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { FormattedMessage } from 'react-intl';
import messages from '../../../../src/messages';
import { useRouter } from 'next/router';
import DialogCreateNewTeam from '../dialogs/DialogCreateNewTeam';
import DialogLockVersion from '../dialogs/DialogLockVersion';
import DialogDuplicateVersion from '../dialogs/DialogDuplicateVersion';
import DialogSwitchTeams from '../dialogs/DialogSwitchTeams';
import { borderRadius22, borderRadius50percent } from '../../global/Variables.js';
import io from 'socket.io-client';
import { LangContext } from '../../../../src/ConnectedIntlProvider';
import DialogAlert from '../dialogs/DialogAlert';
import { log } from '../../../logger';
import { useAppContext } from '../../../../context/AuthContext';

let socket;


export const MainMenuHeaderNew = styled.div`

    @media screen and (max-width: 425px) {
        .menuControlButtons {
          //  display: none;
        }
        .inner-nav .menuButtons{
            padding-left:0 !important;
            padding-right:0 !important;
        }

    }

    @media screen and (max-width: 991px) {
        .breadcrumbs {
            display: none;
        }

    }


    .upgradeDiv {
        // background: #f4f4f4;
        background: white;
        position: fixed;
        bottom: 25px;
        margin-left: 25px;
        margin-right: 30px;
        border-radius: ${borderRadius22};
        padding: 15px;
        z-index: 1000;
        border: thin solid rgba(0, 0, 0, 0.3);

        p {
            font-size: 11pt;
        }

        h4 {
            margin-top: 5px;
            margin-bottom: 5px !important;
        }

        .bulbIcon {
            position: absolute;
            top: 20px;
            height: 1.2rem;
            right: 15px;
            opacity: 0.5;
        }

        @media (max-width: 400px) {
            display: none;
        }

    }
    

    li {
        &:hover {
         //   cursor: pointer;
        }
    }


    /* ==============================
       Top bar
       ============================== */

    .top-bar {
        min-height: 40px;
        background: #fbfbfb;
        border-bottom: 1px solid rgba(0, 0, 0, .05);
        font-size: 11px;
        letter-spacing: 1px;
    }

    /*
     * Top links
     */

    .top-links {
        list-style: none;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
    }

    .top-links li {
        float: left;
        border-left: 1px solid rgba(0, 0, 0, .05);
    }

    .top-links li:last-child {
        border-right: 1px solid rgba(0, 0, 0, .05);
    }

    .top-links li .fa {
        font-size: 14px;
        position: relative;
        top: 1px;
    }

    .top-links li .tooltip {
        text-transform: none;
        letter-spacing: normal;
        z-index: 2003;

    }

    .top-links li a {
        display: block;
        min-width: 40px;
        height: 40px;
        line-height: 40px;
        padding: 0 10px;
        text-decoration: none;
        text-align: center;
        color: #999;

        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        -webkit-transition: all 0.1s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -moz-transition: all 0.1s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -o-transition: all 0.1s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -ms-transition: all 0.1s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        transition: all 0.1s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    }

    .top-links li a:hover {
        color: #555;
        background-color: rgba(0, 0, 0, .02);
    }

    /*
     * Top bar dark
     */

    .top-bar.dark {
        background-color: #333;
        border-bottom: none;
    }

    .top-bar.dark .top-links li,
    .top-bar.dark .top-links li:last-child {
        border-color: rgba(255, 255, 255, .07);
    }

    .top-bar.dark .top-links li a {
        color: #aaa;
    }

    .top-bar.dark .top-links li a:hover {
        color: #ccc;
        background-color: rgba(255, 255, 255, .05);
    }


    /* ==============================
       Classic menu bar
       ============================== */


    /* Logo (in navigation panel)*/

    .nav-logo-wrap {
        float: left;
        margin-right: 20px;

        h4 {
            font-size: 14px !important;
            color: #fff;
            opacity: 60%;
            //letter-spacing: 2px;
            font-weight: 300;
            //text-transform:uppercase;
        }
    }
.nav-logo-wrap:hover{
    cursor:pointer;
}
.nav-logo-wrap .logo {
        display: flex;
        align-items: center;
        //max-width: 118px;
        height: 75px;
        width: 155px;
        -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        @media (max-width: 400px) {
            width:108px;
        }
    &.mobile{
        width:30px !important;
    }
    }

    .nav-logo-wrap .logo img {
        width: auto;
        max-height: 45%;
        margin-left: 15px;
        height: 22px;

        @media (max-width: 400px) {
          //  height: 20px;
        }
        @media (max-width: 370px) {
          //  height: 15px;
        }

    }

    .nav-logo-wrap .logo:before,
    .nav-logo-wrap .logo:after {
        display: none;
    }

    .logo,
    a.logo:hover {
        font-family: 'Nunito Sans', sans-serif !important;
        font-size: 18px;
        font-weight: 400 !important;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        text-decoration: none;
        color: rgba(0, 0, 0, .9);
    }

    /*
     * Desktop nav
     */

    .main-nav {
        width: 100%;
        height: 75px !important;
        position: relative;
        top: 0;
        left: 0;
        text-align: left;
        background: rgba(255, 255, 255, .97);

        -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, .05);
        -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, .05);
        box-shadow: 0 1px 0 rgba(0, 0, 0, .05);

        z-index: 999999999;

        -webkit-transition: height 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -moz-transition: height 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -o-transition: height 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -ms-transition: height 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        transition: height 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    }


    .small-height {
        height: 54px !important;
    }

    .small-height .inner-nav ul li a {
        height: 54px !important;
        line-height: 54px !important;
    }

    .inner-nav {
        display: inline-block;
        position: relative;
        float: right;
        //padding-right: 5px;
            .packshotButton{
                background:none !important;
                border: none !important;
                outline: none !important;
                &:hover,
                &:focus,
                &:active{
                    background:none !important;
                }

                &.active {
                    color: white !important;
                    opacity: 100%;
                    background: transparent !important;
                }
            }
        .buttonMenuMain {

            //minWidth: "50px",
            //marginLeft: "15px",
            //marginRight: "-30px"
            transition: al 0.3s ease;
            span,svg{
                transition: al 0.3s ease;
            }
            &.mainMenuButton {
               // transition: al 0.3s ease;

                &:hover,
                &.active {
                    opacity: 100%;

                    svg, span {
                        opacity: 100%;
                    }
                }
            }
        }

        .faMenuBars {
            filter: invert(1);
        }

        .notificationBell {


            padding-right: 10px;
            max-height: 54px;
            height: 54px;

            svg {
                top: 8px;
                left: 12px;
            }

            .notificationBellIcon {
                filter: invert(1);
                transition: all 0.3s ease;
              //  opacity: 60%;

            }

            &:hover,
            &.active {
                opacity: 100% !important;
                cursor:pointer;
                .notificationBellIcon {
                    opacity: 100% !important;

                }

            }
        }

        .alertNumber {
            background: red;
            border-radius: ${borderRadius50percent};
            -webkit-filter: invert(0);

            filter: invert(0);
            font-size: 6pt !important;
            opacity: 100% !important;
            font-weight: 600;
            color: white;
            top: -2px;
            left: 10px;
            width: 18px;
            display: inline-block;
            height: 18px;
            position: relative;
            line-height: 2.3;

            transition: all 0.3s ease;
            &.opacityHide{
                opacity:0 !important;
            }
        }

        .menuControlButtons {
            margin-top: 8px;
            float: left;
        }

        .menuButtons {
            padding: 5px;
            padding-left: 15px;
            padding-right: 15px;
            margin-right: 5px;
            transition: all 0.3s ease;

            span {

            }

            color: white;
            opacity: 60%;

            .iconBtn {
                font-size: 14px;
                //filter:invert(1);
                color: white;
                margin-right: 8px;
                opacity: 60%;
                //style={{height: "1.6em", filter:"invert(1)"}}
            }

            &:hover {
                color: white !important;
                opacity: 100%;
                background: transparent !important;

                span {
                    opacity: 100%;
                }

                .iconBtn {
                    opacity: 100%;
                    //filter:invert(1);
                }
            }

            //&.renderButton{
            //  &:hover{
            //    background:#3d55d7 !important;
            //    color:white !important;
            //    span{
            //      opacity:100%;
            //      color:white !important;
            //    }
            //    .iconBtn{
            //      opacity:100%;
            //      filter:invert(0);
            //    }
            //  }
            //}
        }
        
        .renderButton{
            &:hover,
            &:active,
            &:focus{
                background: #00205A !important;
            }
        }

        .mainMenuUl {
            max-height: 54px;
            padding: 0;
             width: 125px;
            transition:all 0.3s ease-in-out;
            >li{
                opacity: 0.6;
                transition:all 0.3s ease-in-out;
                &:hover{
                    transition:all 0.3s ease-in-out;
                    opacity:1;
                }
            }

            &.withNotification {
                width: 135px;
            }
            >.mainMenuButton{
                &:hover{
                    cursor:pointer;
                }
            }

            //li{
            //  max-height: 54px;
            //}

        }
    }

    .inner-nav ul {
        float: right;
        margin: auto;
        font-size: 11px;
        font-weight: 400;
        letter-spacing: 1px;
        text-transform: uppercase;
        text-align: center;
        line-height: 1.3;
        list-style-type: none;
    }

    .inner-nav ul li {
        float: left;
        //margin-left: 30px;
        position: relative;
        line-height: 3.9;

        svg,
        img {
            font-size: 14px;
            top: 0px;
            position: relative;
            width: 14px;
        }

        img {
           // border: 1px solid #565656;
        }

        //svg{
        //  //filter:invert(1);
        //  
        //  top: 2px;
        //}

        > div {
            line-height: 1.3;
        }

        img {
            border-radius: ${borderRadius50percent};
            top: -2px;
        }
    }

    .inner-nav ul li a {
        color: #777;
        letter-spacing: 2px;
        display: inline-block;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        text-decoration: none;

        -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    }

    .inner-nav ul > li:first-child > a {

    }

    //.inner-nav ul li a:hover,
    //.inner-nav ul li a.active{
    //  color: #000;
    //  text-decoration: none;
    //  
    //  svg{
    //    color: #000;
    //  }
    //}


    /* Menu sub */

    .mn-sub {
        //display: none;
      width: 260px;
        position: absolute;
        //top: 100%;
        //left: 0;
        padding: 0;
        //background: rgba(30,30,30, .97);
        //transform: translateY(-500px);
        opacity: 0;
        border-bottom-left-radius: 22px;
        border-bottom-right-radius:0;
        right: -27px !important;
       
        transition: all 0.3s ease;
        background: black;
      //  max-height:312px;
        top: 53px;
        visibility: hidden;
          padding-left: 10px;
          padding-bottom:10px;
          
        height:fit-content;
        overflow-y:scroll;
      .dividerText{
        font-size: 18px !important;
        position: relative;
        color: #666;

        text-align: center;
        

        font-style: normal;
        font-weight: 300;
        line-height: normal;
        width: 90%;
        position: relative;
        margin:auto auto;
        display: block;

        &:before {
          content: "";
          display: block;
        
          height: 1px;
          background: #333;
          left: 0;
          top: 50%;
          width: 70px;
          position: absolute;
        }


        &:after {
          content: "";
          display: block;
          width: 70px;
          height: 1px;
          background: #333;
          right: 0;
          top: 50%;
          position: absolute;
        }
        
        &.team{
          &:before {
            width: 60px;
          }


          &:after {
            width: 60px;
          }
        }
        
      }

        .teamSwitcher {
            img {
                margin-right: 10px;
                //max-width: 15px;
                //
                //width: 15px;
                //height: 15px;
            }

            svg {
                //width: 15px;
                margin-right: 10px;
            }

            &.active {
                background: rgba(255, 255, 255, .09);
                color: #f5f5f5 !important;
            }
          span{
            color: var(--black-40, #999);
            font-family: "Nunito Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        &.show {
            //   display:block;
            visibility: visible;
            opacity: 1 !important;
            //transform: translateY(0);
        }

    
        .userInfo,
        .teamInfo{
            text-align: left !important;
            padding-bottom: 10px;

            h5 {
         
     
      
                text-align: left !important;
                margin-top: 15px;
              
              color: var(--black-15, #D9D9D9);
              font-family: "Nunito Sans";
              font-size: 16px !important;
              font-style: normal;
              font-weight: 400 !important;
              line-height: normal;
        margin-bottom:0 !important;
                transition:all 0.3s ease;
                opacity:80%;
                &:hover{
                    cursor:pointer;
                    opacity:100%;
                }
              
            }

            span {

              color: var(--black-40, #999) !important;
              font-family: "Nunito Sans";
              font-size: 16px  !important;
              font-style: normal !important;
              font-weight: 300;
              line-height: normal;
            }
            .planLink{
                padding:0;
                margin:0;
            }

            .plan {
              color: var(--black-100, #000) !important;
              font-family: "Nunito Sans" !important;
              font-size: 10px !important;
              font-style: normal;
              padding: 1px 8px;
              font-weight: 700;
              line-height: normal;
              letter-spacing: normal;
              border-radius: 3px;
              background: var(--black-40, #999) !important;
transition:all 0.3s ease;
                &:hover{
                    background:white !important;
                }
            }
          .credits{
            color: var(--black-15, #D9D9D9) !important;
            font-family: "Nunito Sans";
            font-size: 10px !important;
            font-style: normal !important;
            
            font-weight: 700;
            line-height: normal;
              margin-top:7px;
              display:block;
            //margin-left:10px;
          }

            hr {
                width: 85%;
                background: lightgray;
                height: 2px;
                opacity: 1;
                margin: auto;
            }
          
          img{
            width:48px;
            height:48px;
          }
        }

        .userInfo{
            padding-bottom: 20px;
        }
      .help{
          margin-top:10px !important;
      }
      &.sub-teams{

        &:after,
        &:before {
          left: 100%;
          top: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }
        &:after {
          //border-color: black;
          border-left-color: black;
          border-width: 10px;
          margin-top: -10px;
        }
        
        img,
        svg{
          width:48px;
          height:48px;
          max-width:unset;
        }
        
        .createTeam{
          color: var(--black-40, #999) !important;
          /* Top Menu/Item */
          font-family: "Nunito Sans";
          font-size: 14px !important;
          font-style: normal !important;
          font-weight: 400 !important;
          line-height: normal !important;
        }
        
      }

  
        
        &.menuInside{
            height:540px;
            &.appExternal{
                height: 475px;
            }
         
            img,svg{
                top:2px;
            }
            @media(max-height: 600px){
                height:400px;
            }
            @media(max-height: 500px){
                height:350px;
            }
            @media(max-height: 400px){
                height:300px;
            }
            @media(max-height: 360px){
                height:200px;
            }
        }
        
        &.teamSelect{
            height:275px;
            img,svg{
                top:2px;
            }
        }
        
        .lang_switch{
            span{
                opacity:80% !important;
                font-weight:500 !important;
                transition:all 0.3s ease;

                &:hover,&.active{
                
                        opacity:100% !important;
                    
                }
            }
      
        }
    }

    .mn-sub li {
        display: block;
        width: 100%;
        margin: 0 !important;

        hr {
            width: 85%;
            background: lightgray;
            height: 2px;
            opacity: 1;
            margin: auto;
        }


    }

    .inner-nav ul li .mn-sub li a {
        display: block;
        width: 100%;
        height: auto !important;
        line-height: 1.3 !important;
        position: relative;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        margin: 0;
        padding: 10px 15px;
        text-align: left;
        text-transform: none;
        border-left: none;
        border-right: none;
        letter-spacing: 0;
      
      color: var(--black-40, #999) !important;
      /* Top Menu/Item */
      font-family: "Nunito Sans";
      font-size: 14px  !important;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      
        outline-offset: -2px !important;
      //  cursor: pointer;
        
        svg{
            color:#808080;
        }
    }

    .inner-nav ul li .mn-sub li a span {

        font-size: 13px;

        color: #b0b0b0 !important;


    }


    .mobile-on .mn-has-multi > li:last-child > a {
        border-bottom: 1px solid rgba(255, 255, 255, .065) !important;
    }

    .mn-sub li a,
    .inner-nav ul li .mn-sub li a,
    .mn-sub li a {

        svg{
 
            transition:all 0.3s ease;
        }
    }

    
    .mn-sub li a:hover,
    .inner-nav ul li .mn-sub li a:hover,
    .mn-sub li a.active {
    ///    background: rgba(255, 255, 255, .09);
        color: #f5f5f5 !important;
        svg{
            color: #f5f5f5 !important;
            transition:all 0.3s ease;
        }
    }

    .mn-sub li ul {
        left: 100%;
        right: auto;
        top: 0;
        border-left: 1px solid rgba(255, 255, 255, .07);
    }

    .inner-nav > ul > li:last-child .mn-sub,
    .inner-nav li .mn-sub.to-left {
        left: auto;
        right: 0;
    }

    .inner-nav > ul > li:last-child .mn-sub li ul,
    .inner-nav > li:last-child .mn-sub li ul,
    .inner-nav li .mn-sub li ul.to-left {
        left: auto;
        right: 100%;
        top: 0;
        border-left: none;
        border-right: 1px solid rgba(255, 255, 255, .07);
    }

    .mobile-on .inner-nav li:last-child .mn-sub li ul {
        left: auto;
        right: auto;
        top: 0;
    }

    .mn-wrap {
        padding: 8px;
    }

    /* Menu sub multi */

    .mn-has-multi {
        width: auto !important;
        padding: 15px;
        text-align: left;
        white-space: nowrap;
    }

    .mn-sub-multi {
        width: 190px !important;
        overflow: hidden;
        white-space: normal;
        float: none !important;
        display: inline-block !important;
        vertical-align: top;
    }

    .mn-sub-multi > ul {
        width: auto;
        margin: 0;
        padding: 0;
        float: none;
    }

    .mn-group-title {
        font-weight: 700;
        text-transform: uppercase;
        color: #fff !important;
    }

    .mn-group-title:hover {
        background: none !important;
    }

    .mobile-on .mn-sub-multi > ul {
        left: auto;
        right: auto;
        border: none;
    }

    .mobile-on .mn-sub-multi {
        display: block;
        width: 100% !important;
    }


    .mobile-on .mn-sub-multi > ul > li:last-child > a {
        border: none !important;
    }

    .mobile-on .mn-sub:not(.mn-has-multi) > li:last-child > a {
        border: none !important;
    }

    .mobile-on .mn-sub-multi:last-child > ul {
        border-bottom: none !important;
    }

    /*
     * Mobile nav
     */

    .mobile-on .desktop-nav {
        display: none;
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        padding: 0 15px;

        z-index: 100;
    }

    .mobile-on .full-wrapper .desktop-nav {
        padding: 0;
    }


    .mobile-on .desktop-nav ul {
        width: 100%;
        float: none;
        background: rgba(18, 18, 18, .97);
        position: relative;
        overflow-x: hidden;
    }

    .mobile-on .desktop-nav > ul {
        overflow-y: auto;
    }

    .mobile-on .desktop-nav ul li {
        display: block;
        float: none !important;
        overflow: hidden;
    }

    .mobile-on .desktop-nav ul li ul {
        padding: 0;
        border-bottom: 1px solid rgba(255, 255, 255, .1);
    }

    .mobile-on .desktop-nav ul li a {
        display: block;
        width: 100%;
        height: 38px !important;
        line-height: 38px !important;
        position: relative;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 10px;
        text-align: left;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid rgba(255, 255, 255, .065);
        color: #999;
        letter-spacing: 1px;
      //  cursor: pointer;
    }

    .mobile-on .desktop-nav ul li a:hover,
    .mobile-on .desktop-nav ul li a.active {
        background: rgba(255, 255, 255, .03);
        color: #ddd;
    }

    .mobile-on .desktop-nav ul li a:focus {
        outline-offset: -2px !important;
    }


    /* Menu sub */

    .mobile-on .mn-has-sub > .fa {
        display: block;
        width: 18px;
        height: 18px;
        font-size: 14px;
        line-height: 16px;
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -8px;
        text-align: center;
        border: 1px solid rgba(255, 255, 255, .1);

       
        border-radius: ${borderRadius50percent};
    }

    .mobile-on .js-opened > .mn-has-sub > .fa {
        border-color: rgba(255, 255, 255, .4);
        line-height: 14px;
    }

    .mobile-on .inner-nav li {
        margin: 0 !important;
    }

    .mobile-on .mn-sub {
        display: none;
        opacity: 0;
        position: relative;
        left: 0 !important;
        right: 0;
        top: 0;
        bottom: 0;
        border-left: none !important;
        border-right: none !important;
        border-top: none;
        z-index: 10;

        &.show {
            display: block;
        }
    }

    .mobile-on .mn-sub li {
        padding-left: 10px;
    }


    .inner-nav li.js-opened .mn-sub {
        opacity: 1;
    }


    .mobile-nav {
        display: none;
        float: right;
        vertical-align: middle;
        font-size: 11px;
        font-weight: 400;
        text-transform: uppercase;
        border-left: 1px solid rgba(0, 0, 0, .05);
        border-right: 1px solid rgba(0, 0, 0, .05);
        cursor: pointer;

        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;

        -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);

        -webkit-transition-property: background, color, height, line-height;
        -moz-transition-property: background, color, height, line-height;
        -o-transition-property: background, color, height, line-height;
        transition-property: background, color, height, line-height;
    }

    .mobile-nav:hover,
    .mobile-nav.active {
        background: #fff;
        color: #777;
    }

    .mobile-nav:active {
        -webkit-box-shadow: 0 0 35px rgba(0, 0, 0, .05) inset;
        -moz-box-shadow: 0 0 35px rgba(0, 0, 0, .05) inset;
        box-shadow: 0 0 35px rgba(0, 0, 0, .05) inset;
    }

    .no-js .mobile-nav {
        width: 54px;
        height: 54px;
    }

    .mobile-on .mobile-nav {
        display: table;
    }

    .mobile-nav .fa {
        height: 100%;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        font-size: 21px;
    }

    /* Menu sub multi */

    .mobile-on .mn-has-multi {
        width: auto;
        padding: 0;
        text-align: left;
        white-space: normal;

    }

    .mobile-on .mn-sub {
        width: 100%;
        float: none;
        position: relative;
        overflow-x: hidden;
    }

    /*
     * Dark panel style
     */

    .main-nav {
        span {
            font-weight: 400;
            text-transform: none;
            font-size: 14px !important;
            color: white;
           // opacity: 60%;
        }

        .subVersions {
            left: -43px;
            top: 53px;
            color: white;
            padding: 22px;
            opacity: 0;
            //z-index: -1;
            border-bottom-left-radius: 22px;
            background: black;
            border-bottom-right-radius: 22px;
            padding-top: 10px;
            padding-right: 40px;
            max-height:312px;
            li {
                margin-top: 15px !important;
                line-height: 1 !important;
                font-size: 14px;
                opacity: 60%;
                transition: all 0.3s ease;

                &:hover {
                    opacity: 100%;
                }
            }

            //&.show{
            //  opacity:1;
            //}
        }

        .notificationMenu {
            width: 350px;
            left: -213px;
            top: 54px;
            padding-top: 0;
            max-height: 800px;
            overflow-y: scroll;
            .notification {
                border-bottom: 1px solid lightgray;
                padding-bottom: 10px;
                max-height: unset !important;
                padding-top: 15px;
            }

            .notificationIcon {
                width: 20%;
                display: inline-block;
                float: left;
                font-size: 14px;

                svg {
                    width: 100%;
                    left: 0;
                    top: 0;
                }
            }

            .notificationDesc {
                width: 80%;
                display: inline-block;
                float: left;
                text-align: left;

                h6 {
                    font-size: 14px !important;
                    text-align: left;
                    margin-bottom: 3px !important;
                    text-transform: none !important;
                    font-weight: 200;
                }

                span {
                    font-size: 11px !important;
                    opacity: 80%;
                    font-weight: 200;
                }
            }
        }

        .breadcrumbs {
            float: left;
            line-height: 3.4;
            margin-left: -4px;
            //#currentPackageBreadcrumbs{
            // max-width:300px;
            //    overflow:hidden;
            //    max-height: 50px;
            //    
            //}

            ul {
                list-style-type: none;

                li {
                    float: left;
                    display: inline-block;
                    position: relative;
                    z-index: 8;
                    opacity:60%;
                    transition:all 0.3s ease;

                    &::marker {
                        display: none;
                    }

                    ::marker {
                        display: none;
                    }

                    &:hover,
                    &.active{    
                        opacity:60%;
                    span{
                        opacity:100%;
                    }
                            opacity: 100%;
                        

                    }
                }
            }

            .breadcrumbArrow {
                font-size: 9pt;
                opacity: 60%;
                margin-left: 10px;
                margin-right: 10px;
                -webkit-filter: invert(1);
                filter: invert(1);
                position: relative;
                top: 7px;
                svg{
                    height:10px;
                }
            }

            .breadcrumbsButtons {
                //margin-left:15px;
                //width: 80px;
                top: 12px;
                display: inline-block;
                border-radius: ${borderRadius50percent};
                position: relative;
                //top:6px;
                height: 30px;
                width: 30px;
                transition: all 0.3s ease;

                svg:not(:first-child) {
                    margin-left: 5px;
                }

                .brButton {
                    transition: all 0.3s ease;

                    opacity: 100%;
                    // color:black;
                    padding: 0;
                    position: relative;
                    font-size: 14px;
                    margin: 0;
                    top: -12px;
                    left: 8px;

                    &:active,
                    &:focus,
                    &:hover {
                        outline: none;
                    }
                }

                &:hover {
                    opacity: 100%;
                    //background:darkgray;

                    .brButton {
                        opacity: 100%;
                    }
                }
            }
        }


        .menuIcon {
            font-size: 14pt;
            //margin-left:10px;
            filter: invert(1);
        }
    }

    .main-nav.dark {
        background-color: black;

        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    .main-nav.dark .inner-nav ul > li > a {
        color: rgba(255, 255, 255, .7);
    }

    .main-nav.dark .inner-nav ul > li > a:hover,
    .main-nav.dark .inner-nav ul > li > a.active {
        color: #fff;
    }

    .main-nav.dark .mobile-nav {
        background-color: transparent;
        border-color: transparent;
        color: rgba(255, 255, 255, .9);
    }

    .main-nav.dark .mobile-nav:hover,
    .main-nav.dark .mobile-nav.active {
        color: #fff;
    }

    .main-nav.mobile-on.dark .inner-nav {
        border-top: 1px solid rgba(100, 100, 100, .4);
    }

    .main-nav.dark .logo,
    .main-nav.dark a.logo:hover {
        font-size: 18px;
        font-weight: 800;
        text-transform: uppercase;
        text-decoration: none;
        color: rgba(255, 255, 255, .9);
    }

    .main-nav.dark .mn-sub {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    .main-nav.dark .mn-soc-link {
        border: 1px solid rgba(255, 255, 255, .2);
    }

    .main-nav.dark .mn-soc-link:hover {
        border: 1px solid rgba(255, 255, 255, .3);
    }

    /*
     * Transparent panel style
     */

    .main-nav.transparent {
        background: transparent !important;
    }

    .main-nav.js-transparent {
        -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    }


    /* 
     * Centered menu 
     */

    .mn-centered {
        text-align: center;
    }

    .mn-centered .inner-nav {
        float: none;
    }

    .mn-centered .inner-nav > ul {
        float: none;
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
    }

    .mn-centered .inner-nav > ul > li:first-child {
        margin-left: 0;
    }

    .mn-centered .mobile-nav {
        float: none;
        margin-left: auto;
        margin-right: auto;
    }



`;

export default function MainMenu({
                                     typePlugin,
                                     typeEditor,
                                     sideMenuPage,
                                     canBeRendered,
                                     userRoleEditor,
                                     breadcrumbsAPI,
                                     showUserMenu,
                                     changeShowUserMenu,
                                     typeShareView,
                                     hitTriggerShowRender,
                                     masterProductUUID,
                                     showNotifications,
                                     changeShowNotifications,
                                     setTeamCredits,
                                     versionProductUUID,
                                     versionIsLocked,
                                     setVersionIsLocked,
                                 }) {
    const router = useRouter();
    const { slug } = router.query;

    const { language, setLanguage } = useContext(LangContext);


    //
    // const [teamCreditsInner, setTeamCreditsInner] = useState(0);


    const [slugState, setSlugState] = useState(null);

    const [showSwitchTeams, changeShowSwitchTeams] = useState(false);

  //  const [showNotifications, changeShowNotifications] = useState(false);
    const [session, loading] = useSession();

    const [showVersions, changeShowVersions] = useState(false);
    const [dropdownAllowed, changeDropdownAllowed] = useState(true);

    // const [userDeveloper, setUserDeveloper] = useState(false);


    const changeShowDropdownFunction = (value, type) => {

        if (dropdownAllowed && value) {
            if (type === 'versions') {
                changeShowVersions(value);
            }
            if (type === 'packageType') {
                changeShowPackageType(value);
            }
            if (type === 'brand') {
                changeShowBrand(value);
            }
            if (type === 'edition') {
                changeShowEdition(value);
            }
            if (type === 'client') {
                changeShowClient(value);
            }
            changeDropdownAllowed(false);

            setTimeout(function() {
                changeDropdownAllowed(true);
            }, 300);
        } else {
            changeShowVersions(false);
            changeShowPackageType(false);
            changeShowBrand(false);
            changeShowEdition(false);
            changeShowClient(false);
            changeShowBrand(false);
            setTimeout(function() {
                changeDropdownAllowed(true);
            }, 300);

        }


    };


    const { authData, refreshAuthInfo, setLanguageFunction, loadTeamInfoAPI, allLanguages } = useAppContext();
    const [showPackageType, changeShowPackageType] = useState(false);

    const [showClient, changeShowClient] = useState(false);
    const [showBrand, changeShowBrand] = useState(false);
    const [showEdition, changeShowEdition] = useState(false);


    //

    useEffect(() => {
                if(session){

                    // loadLanguages();

                }


        setSlugState(slug);

    }, [session]);


    const [currentClient, setCurrentClient] = useState(null);
    const [allClients, setAllClients] = useState([]);

    const [currentBrand, setCurrentBrand] = useState(null);
    const [allBrands, setAllBrands] = useState([]);

    const [currentEdition, setCurrentEditions] = useState(null);
    const [allEditions, setAllEditions] = useState([]);

    const [breadcrumbsProducts, setBreadcrumbsProducts] = useState([]);
    const [breadcrumbsVersions, setBreadcrumbsVersions] = useState([]);

    const [currentProduct, setCurrentProduct] = useState(null);
    const [currentVersion, setCurrentVersion] = useState(null);


    useEffect(() => {
        if (breadcrumbsAPI) {

            setAllClients(breadcrumbsAPI.clients);
            setAllBrands(breadcrumbsAPI.brands);
            setAllEditions(breadcrumbsAPI.editions);
            setBreadcrumbsProducts(breadcrumbsAPI.products);
            setBreadcrumbsVersions(breadcrumbsAPI.versions);


            breadcrumbsAPI.clients.map((client => {
                if (client.current) {
                    setCurrentClient(client);
                }
            }));

            breadcrumbsAPI.brands.map((brand => {
                if (brand.current) {
                    setCurrentBrand(brand);
                }
            }));

            breadcrumbsAPI.editions.map((editions => {
                if (editions.current) {
                    setCurrentEditions(editions);
                }
            }));

            breadcrumbsAPI.products.map((product => {
                if (product.current) {
                    setCurrentProduct(product);
                }
            }));
            breadcrumbsAPI.versions.map((version => {
                if (version.current) {
                    setCurrentVersion(version);
                }
            }));


        }
    }, [breadcrumbsAPI]);


    const switchTeam = (teamUUID) => {

        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'auth/switch-team/' + teamUUID, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + session.accessToken, // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function(data) {

                if (data.message !== undefined) {
                    Sentry.captureException('auth/switch-team/' + teamUUID + ': ' + data.message + ' : ' + getCookie('userMail'));
                }
                if (data.token !== undefined && data.token !== null) {

                    setCookie('userTeam', data.token, { maxAge: 10 * 365 * 24 * 60 * 60 });
                    setCookie('userTeamUUID', teamUUID, { maxAge: 10 * 365 * 24 * 60 * 60 });

                    router.reload(window.location.pathname);
                }
            });

    };

    const [showShareDialog, setShowShareDialog] = useState(false);
    const [showShareDialogTransition, setShowShareDialogTransition] = useState(false);

    const changeShareShow = (variable) => {

        if (variable === true) {
            setShowShareDialog(true);

            setTimeout(function() {
                setShowShareDialogTransition(variable);
            }, 2);


        } else {


            setShowShareDialogTransition(false);
            setTimeout(function() {
                setShowShareDialog(variable);
            }, 500);


        }

    };

    const [showLockDialog, setShowLockDialog] = useState(false);
    const [showLockDialogTransition, setShowLockDialogTransition] = useState(false);
    const changeLockShow = (variable) => {
        if (variable === true) {
            setShowLockDialog(true);
            setTimeout(function() {
                setShowLockDialogTransition(variable);
            }, 2);
        } else {
            setShowLockDialogTransition(false);
            setTimeout(function() {
                setShowLockDialog(variable);
            }, 500);
        }
    };

    // };

    const changeMenuTypeOpened = (type) => {


        if (type === 'menu' && !showUserMenu && showNotifications) {
            changeShowNotifications(false);
            changeShowUserMenu(true);
        }
        if (type === 'menu' && !showUserMenu && !showNotifications) {
            changeShowUserMenu(true);
        }

        if (type === 'menu' && showUserMenu) {
            changeShowUserMenu(false);
        }

        if (type === 'notification' && !showNotifications && showUserMenu) {
            changeShowUserMenu(false);
            changeShowNotifications(true);
            readAllNotifications();
        }
        if (type === 'notification' && !showNotifications && !showUserMenu) {
            changeShowNotifications(true);
            readAllNotifications();
        }

        if (type === 'notification' && showNotifications) {
            changeShowNotifications(false);
        }

    };

    const lockProductVersion = (masterProductUUID, versionProductUUID) => {


        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'products/' + masterProductUUID + '/versions/' + versionProductUUID, {
            method: 'PUT',
            body: JSON.stringify({
                'locked': versionIsLocked ? false : true,
            }),
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + getCookie('userTeam'), // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function(data) {

                if (data.message !== undefined) {
                    Sentry.captureException('products/' + masterProductUUID + '/versions/' + versionProductUUID + ': ' + data.message + ' : ' + getCookie('userMail'));
                }
                if (data.message === undefined) {
                    setVersionIsLocked(data.data.locked.locked);
                    changeLockShow(false);
                }


            });
    };


    const [enableNotifications, setEnableNotifications] = useState(true);
    const [allNotifications, setAllNotifications] = useState([]);

    const [newNotificationsCount, setNewNotificationsCount] = useState(0);

   /* const [teamPlan, setTeamPlan] = useState("Free");
*/
    const loadNotifications = () => {

        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'notifications', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + getCookie('userTeam'), // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function(data) {

                if (data.message !== undefined) {
                    Sentry.captureException('notifications' + ': ' + data.message + ' : ' + getCookie('userMail'));
                } else {
                    var notificationsNew = 0;

                    setAllNotifications(data);



                    data.map((value, index) => {
                        if(value.status !== "read" && value.status !== "seen"){
                            notificationsNew = notificationsNew+1;
                        }
                    })

                    setNewNotificationsCount(notificationsNew);
                }





            });
    };


    const readAllNotifications = () => {

        var originalNotifications = newNotificationsCount;
        setNewNotificationsCount(0);

        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'notifications/set/seen', {
            method: 'PUT',
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + getCookie('userTeam'), // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function(data) {
                if (data.message !== undefined) {
                    Sentry.captureException('notifications/set/seen' + ': ' + data.message + ' : ' + getCookie('userMail'));
                    setNewNotificationsCount(originalNotifications);
                } else {

                    setNewNotificationsCount(0);
                }



            });
    };
    let handleClickDuplicateVersion = () => {
        
    };


    //
    // const loadUserInfoAPI = (teamSelect) => {
    //     var tokenMain;
    //
    //     if (typePlugin) {
    //
    //             tokenMain = getCookie('pluginLoginSessionToken');
    //
    //
    //     } else {
    //
    //         if(teamSelect){
    //             tokenMain = session.accessToken;
    //         } else {
    //
    //             if( getCookie('userTeam')){
    //                 tokenMain = getCookie('userTeam');
    //             } else {
    //                 tokenMain = session.accessToken;
    //             }
    //
    //         }
    //     }
    //
    //     if(tokenMain){
    //         fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'auth/info', {
    //             method: 'GET',
    //             headers: {
    //                 'Content-type': 'application/json',
    //                 'Authorization': 'Bearer ' + tokenMain, // notice the Bearer before your token
    //             },
    //         })
    //             .then(response => response.json())
    //             .then(function(data) {
    //
    //                 if (data.message === 'Username could not be found.') {
    //                     if(typePlugin){
    //                         signOutPlugin();
    //                     } else {
    //                         signOutFunction();
    //                     }
    //
    //
    //                 }
    //
    //                 if(data.status === 401 || data.status === 403){
    //
    //                     if(typePlugin){
    //                         signOutPlugin();
    //                     } else {
    //                         signOutFunction();
    //                     }
    //                 } else {
    //
    //                     if (data.user.avatar && data.user.avatar !== undefined) {
    //                         setUserAvatar(data.user.avatar);
    //                     }
    //
    //
    //
    //                     if(data.user) {
    //                         setUserDeveloper(data.user.developer);
    //                     }
    //
    //                     setUserEmail(data.user.email);
    //                     setUserName(data.user.full_name);
    //                     setUserUUID(data.user.uuid);
    //
    //
    //
    //                     setUserTeams(data.teams);
    //
    //                     if (!getCookie('userMail') && (getCookie('userMail') !== data.user.email)) {
    //                         setCookie('userMail', data.user.email, { maxAge: 10 * 365 * 24 * 60 * 60 });
    //                     }
    //                     log("DATA USER PREFERED ", data);
    //
    //
    //                     if(data.message === undefined && data.user && data.user.preferred_language !== null){
    //                         setLanguage(data.user.preferred_language.code);
    //                     } else {
    //                         setNeedToInitLang(true);
    //                         //   setLanguage(getBrowserLocale());
    //                     }
    //
    //                     if(data.message === undefined && data.user && data.user.country === null){
    //                         setNeedToInitCountry(true);
    //                     }
    //
    //
    //                     if(data.message === undefined && data.user && data.user.timezone === null){
    //                         setNeedToInitTimezone(true);
    //                     }
    //
    //
    //
    //                     Object.keys(data.teams).map(key => {
    //                         if (getCookie('userTeamUUID') === data.teams[key]['uuid']) {
    //                             setTeamAvatar(data.teams[key]['logo']);
    //
    //                                         // setUserRole(data.teams[key]['role']);
    //
    //                             if(data.teams[key]['subscription'] !== undefined){
    //                                 if(data.teams[key]['credits']){
    //                                     setTeamCreditsInner(data.teams[key]['credits']);
    //                                 } else {
    //                                     setTeamCreditsInner(0);
    //                                 }
    //
    //                                 setTeamPlan(data.teams[key]['subscription']['plan'])
    //                             }
    //
    //
    //                             if(setTeamCredits && data.teams[key]['role'] !== "external"){
    //                                 if(data.teams[key]['subscription'] !== undefined){
    //                                     setTeamCredits(data.teams[key]['credits']);
    //                                 }
    //
    //
    //
    //                                 setTeamCredits(data.teams[key]['credits']);
    //                             } else{
    //                                 if( data.teams[key]['role'] === "external"){
    //                                     setAppExternal(true);
    //                                 }
    //                             }
    //
    //                         }
    //
    //                     });
    //
    //
    //                 }
    //                 if (data.message !== undefined) {
    //                     Sentry.captureException('auth/info' + ': ' + data.message + ' : ' + getCookie('userMail'));
    //                 }
    //
    //             });
    //     }
    //
    //
    // };

    // const [currentPlan, setCurrentPlan] = useState("");
    //
    //
    //
    // const loadTeamInfoAPI = () => {
    //     fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"teams/current", {
    //         method: 'GET',
    //         headers: {
    //             'Content-type': 'application/json',
    //             'Authorization': "Bearer "+ getCookie('userTeam'), // notice the Bearer before your token
    //         },
    //     })
    //         .then(response => response.json())
    //         .then(function(data){
    //
    //             if(data.message !== undefined){
    //                 Sentry.captureException("teams/current"+": "+ data.message + " : " + getCookie("userMail"));
    //
    //             }
    //
    //             if(data.subscription){
    //                 setCurrentPlan(data.subscription.plan);
    //             }
    //
    //             if(!data.preferred_currency ){
    //
    //                 setNeedToInitCurrency(true);
    //             }
    //
    //         });
    // };

    useEffect(() => {
        if (typePlugin) {
            refreshAuthInfo()

        }
        if (authData.userAvatar === 'default' && getCookie('userTeam') && !typeShareView) {
            refreshAuthInfo();
        }


    }, []);


    let handleClickCreateNewTeam = () => {

    };
    let handleLockVersion = () => {

    };


    let signOutPlugin = () => {

        deleteCookie('pluginLoginSessionToken');
        deleteCookie('pluginLoginSessionTeamToken');
        deleteCookie('loginByPlugin');


        router.push('/plugin/adobe_login');
        window.open('/plugin/adobe_login', "_self");
    };

    let signOutFunction = () => {
        deleteCookie('userTeam');
        deleteCookie('userTeamUUID');
        deleteCookie('usrA');

        signOut({ redirect: false }).then(() => {
            router.push('/'); // Redirect to the dashboard page after signing out
            window.location.reload();
        });

        // signOut();
    };

    //


    const acceptInvite = (invitationUUID, inviteTeamUUID) => {

        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'team-invitations/' + invitationUUID + '/accept', {
            method: 'PUT',
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + getCookie('userTeam'), // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function(data) {

                if (data.message === undefined) {

                    fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'auth/switch-team/' + inviteTeamUUID, {
                        method: 'POST',
                        headers: {
                            'Content-type': 'application/json',
                            'Authorization': 'Bearer ' + getCookie('userTeam'), // notice the Bearer before your token
                        },
                    })
                        .then(response => response.json())
                        .then(function(data) {

                            if (data.token !== undefined && data.token !== null) {

                                setCookie('userTeam', data.token, { maxAge: 10 * 365 * 24 * 60 * 60 });
                                setCookie('userTeamUUID', inviteTeamUUID, { maxAge: 10 * 365 * 24 * 60 * 60 });

                                router.reload(window.location.pathname);
                            }
                        });

                }
            });
    };

    const declineInvite = (invitationUUID) => {
        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'team-invitations/' + invitationUUID + '/reject', {
            method: 'PUT',
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + getCookie('userTeam'), // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function(data) {
                loadNotifications();
                if (data.message === undefined) {

                    changeShowNotifications(false);

                }
            });
    };


    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }
    let handleClickSwitchTeams = () => {

    };

    const setUpSockets = () => {

        if (socket !== undefined) {

            socket.close();
            socket.disconnect();
            socket.emit('end');
            socket.offAny();
            socket.removeAllListeners();
            socket = null;
        }


        socket = io(process.env.NEXT_PUBLIC_OBALERO_SOCKET_BASEPATH + 'notifications', {
            reconnectionDelayMax: 10000,
            auth: {
                token: getCookie('userTeam'),
            },
        });
        socket.onAny((event, ...args) => {

            loadNotifications();
        });
        socket.on('connect', () => {

        });
        socket.on('data', data => {

        });
        socket.on('create', data => {

            loadNotifications();
            if (data.status === 'pending') {

            }
        });
        socket.on('update', data => {

            if (data.status === 'pending') {

            }

            if (data.status === 'queued') {

            }
            if (data.status === 'rendering') {

            }

            if (data.status === 'completed') {

            }
            if (data.status === 'error') {

            }
        });

    };

    const [socketInited, setSocketInited] = useState(false);

    useEffect(() => {
        if(!socketInited){

            if(getCookie("userTeam") ){
                loadNotifications();
                setSocketInited(true);
                setUpSockets();
             //   loadTeamInfoAPI();
            }

        }

    }, []);


    const [warningType, setWarningType] = useState("warning");
    const [warningMessage, setWarningMessage] = useState("");
    let triggerWarning = (warningMessage) => {
        setWarningType("warning");
        setWarningMessage(warningMessage);
        handleTriggerWarning();
    };
    let handleTriggerWarning = () => {

    };

    const downloadPackshot = async (packshotUrl) => {
        try {
            const response = await fetch(packshotUrl, { mode: 'cors' });
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'packshot.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Chyba při stahování obrázku:', error);
        }
    }



    return (
        <>
        <MainMenuHeaderNew style={typePlugin ? { position: 'relative', zIndex: '99999999999999999999' } : null}>

            <nav className="main-nav dark stick-fixed js-transparent small-height">
                <div className="full-wrapper relative clearfix">

                    <div className="nav-logo-wrap local-scroll">


                        <div style={{ float: 'left' }} >

                            {window.innerWidth > 400 ?
                                <div className="logo small-height">
                                    <Link href={'/'}><img src={'/images/logoObalero_beta.svg'} /></Link>
                                </div> :
                                <div className="logo small-height mobile">
                                    <Link href={'/'}><img src={'/images/logoMobile.svg'} /></Link>
                                </div>
                            }


                        </div>


                        {typeEditor && !typePlugin ?
                            <div style={{ float: 'left' }} className={'breadcrumbs'}
                                 id={"breadcrumbs"}
                                 data-intercom-target="breadcrumbs"
                            >

                                <ul>

                                    {currentClient ?
                                        <>
                                            <li onMouseEnter={() => changeShowDropdownFunction(true, 'client')}
                                                onMouseLeave={() => changeShowDropdownFunction(false, 'client')} key={"currentClientBreadcrumbs"}  id={"currentClientBreadcrumbs"}>
                                                <span onClick={() => window.open("/?clients="+currentClient.uuid, "_self")}> {currentClient.name} </span>
                                                {allClients.length > 1 ?
                                                    <ul className={` mn-sub subVersions ${showClient ? 'show' : ''}`}>

                                                        {allClients.map((value, index) => {

                                                            return <li className={` ${value.uuid === currentClient.uuid ? 'active' : ''}`} onClick={() => window.open("/?clients="+value.uuid, "_self")}>
                                                                {value.name}
                                                            </li>;


                                                        })}
                                                    </ul>
                                                    : null}
                                            </li>

                                            <li className={'breadcrumbArrow'}>
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                     className={'fa-caret-right halfing'}
                                                     viewBox="0 0 256 512">
                                                    <path
                                                        d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" />
                                                </svg>

                                            </li>
                                        </>
                                        : null}

                                    {currentBrand ?
                                        <>
                                            <li onMouseEnter={() => changeShowDropdownFunction(true, 'brand')}
                                                onMouseLeave={() => changeShowDropdownFunction(false, 'brand')} key={"currentBrandBreadcrumbs"}  id={"currentBrandBreadcrumbs"}>
                                                <span onClick={() => window.open('/?brands=' + currentBrand.uuid, "_self")}> {currentBrand.name} </span>

                                                {allBrands.length > 1 ?
                                                    <ul className={` mn-sub subVersions ${showBrand ? 'show' : ''}`}>

                                                        {allBrands.map((value, index) => {

                                                            return <li
                                                                key={"value"+index+Math.random()}
                                                                className={` ${value.uuid === currentBrand.uuid ? 'active' : ''}`} onClick={() => window.open("/?brands="+value.uuid, "_self")}>
                                                                {value.name}
                                                            </li>;


                                                        })}
                                                    </ul>
                                                    : null}
                                            </li>

                                            <li className={'breadcrumbArrow'} key={"breadcrumbArrow"}>
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                     className={'fa-caret-right halfing'}
                                                     viewBox="0 0 256 512">
                                                    <path
                                                        d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" />
                                                </svg>

                                            </li>
                                        </>
                                        : null}

                                    {currentEdition ?
                                        <>
                                            <li
                                                onMouseEnter={() => changeShowDropdownFunction(true, 'edition')}
                                                onMouseLeave={() => changeShowDropdownFunction(false, 'edition')}
                                                key={"currentEditionBreadcrumbs"}  id={"currentEditionBreadcrumbs"}
                                            >
                                                <span onClick={() => window.open("/?editions="+currentEdition.uuid, "_self")}> {currentEdition.name} </span>

                                                {allEditions.length > 1 ?
                                                    <ul className={` mn-sub subVersions ${showEdition ? 'show' : ''}`}>
                                                        {allEditions.map((value, index) => {
                                                            return <li
                                                                key={value+index+Math.random()}
                                                                className={` ${value.uuid === currentEdition.uuid ? 'active' : ''}`}  onClick={() => window.open("/?editions="+value.uuid, "_self")}>
                                                                {value.name}
                                                            </li>;
                                                        })}
                                                    </ul>
                                                    : null}

                                            </li>
                                            <li className={'breadcrumbArrow'} key={"breadcrumbArrowEdition"}>
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                     className={'fa-caret-right halfing'}
                                                     viewBox="0 0 256 512">
                                                    <path
                                                        d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" />
                                                </svg>

                                            </li>
                                        </>
                                        : null}

                                    {currentProduct ?
                                        <>
                                            <li
                                             //   onClick={() => window.open("/products/"+currentProduct.uuid, "_self")}
                                                onMouseEnter={() => changeShowDropdownFunction(true, 'packageType')}
                                                onMouseLeave={() => changeShowDropdownFunction(false, 'packageType')}
                                                key={"currentPackageBreadcrumbs"}  id={"currentPackageBreadcrumbs"}
                                            >

                                                <span  onClick={() => window.open("/products/"+currentProduct.uuid, "_self")}> {currentProduct.name} </span>

                                                {breadcrumbsProducts.length > 1 ?
                                                    <ul className={` mn-sub subVersions ${showPackageType ? 'show' : ''}`}>

                                                        {breadcrumbsProducts.map((valueBreadcrumbs, index) => {

                                                            return <li className={` ${valueBreadcrumbs.uuid === currentProduct.uuid ? 'active' : ''}`}
                                                                       key={valueBreadcrumbs.uuid+Math.random()}
                                                                       onClick={() => window.open("/products/"+valueBreadcrumbs.uuid, "_self")}>
                                                                {valueBreadcrumbs.name}
                                                            </li>;


                                                        })}
                                                    </ul>
                                                    : null}
                                            </li>
                                            <li className={'breadcrumbArrow'} key={"breadcrumbArrowProduct"}>
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                     className={'fa-caret-right halfing'}
                                                     viewBox="0 0 256 512">
                                                    <path
                                                        d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" />
                                                </svg>

                                            </li>
                                        </>
                                        : null}
                                    {currentVersion ?
                                        <>

                                            <li onMouseEnter={() => changeShowDropdownFunction(true, 'versions')}
                                                onMouseLeave={() => changeShowDropdownFunction(false, 'versions')}
                                                key={"currentVersionBreadcrumbs"}  id={"currentVersionBreadcrumbs"}
                                            >
                                                <span>
                                                         <FormattedMessage {...messages.main_menu_breadcrumbs_version}></FormattedMessage>

                                                    {" "}{currentVersion.version}  </span>
                                                {breadcrumbsVersions.length > 1 ?
                                                    <ul className={` mn-sub subVersions ${showVersions ? 'show' : ''}`}>

                                                        {breadcrumbsVersions.map((value, index) => {

                                                            return <li
                                                                key={value+index+Math.random()}
                                                                className={` ${value.uuid === currentVersion.uuid ? 'active' : ''}`} onClick={() => window.open("/editor/"+value.uuid, "_self")}>
                                                                <FormattedMessage {...messages.main_menu_breadcrumbs_version}></FormattedMessage>

                                                                {" "}{value.version}
                                                            </li>;


                                                        })}
                                                    </ul>
                                                    : null}
                                            </li>
                                        </>
                                        : null}
                                    {!typeShareView && !authData.appExternal?
                                        <li
                                            key={"breadcrumbsButtonLock"}
                                            id={"breadcrumbsButtonLock"}
                                            data-intercom-target="breadcrumbsButtonLock"
                                            className={'breadcrumbsButtons'} style={{ marginLeft: '15px' }}
                                            onClick={() => handleLockVersion()}>
                                            {versionIsLocked ?
                                                <FontAwesomeIcon
                                                    data-tip="Lock version" data-place="bottom" data-delay-show="700"
                                                    className={'menuIcon brButton'}
                                                    icon={faLock}
                                                />
                                                :
                                                <FontAwesomeIcon
                                                    data-tip="Unlock version" data-place="bottom" data-delay-show="700"
                                                    className={'menuIcon brButton'}
                                                    icon={faUnlock} />
                                            }

                                        </li>
                                        : null}
                                    {!typeShareView  && !authData.appExternal && authData.currentPlan !== "Free" ?
                                        <li
                                            key={"breadcrumbButtonClone"}
                                            className={'breadcrumbsButtons'} onClick={()=> handleClickDuplicateVersion()}>
                                            <FontAwesomeIcon
                                                data-tip="Clone version" data-place="bottom" data-delay-show="700"
                                                className={'menuIcon brButton'}
                                                icon={faPlus} />
                                        </li>
                                        : null}


                                </ul>


                            </div>
                            : null}


                    </div>
                    <div className="mobile-nav small-height" role="button" aria-expanded="false"
                         style={{ height: '75px', lineHeight: '75px', width: '75px' }}>
                        <i className="fa fa-bars" aria-hidden="true"></i>
                        <span
                            className="sr-only">
                             <FormattedMessage {...messages.main_menu_title}></FormattedMessage>
                            </span></div>

                    <div className="inner-nav desktop-nav">
                        {typeEditor ?
                            <div className={'menuControlButtons'}>

                                {authData.userDeveloper && canBeRendered?

                            <GlobalButtonFinal className={"btn-circle backgroundBlue menuButtons renderButton"} onClick={() => hitTriggerShowRender(true)} data-tip="Packshots" data-place="bottom" data-delay-show="700">
                                    <FontAwesomeIcon className={"iconBtn"} icon={faCamera}/>
                                    <span>  <FormattedMessage {...messages.main_menu_button_render_packshot}></FormattedMessage></span>
                                </GlobalButtonFinal>: null}

                                {!typeShareView &&!authData.appExternal && userRoleEditor !== "view" ?

                                    <GlobalButtonFinal className={'btn-circle backgroundTransparent menuButtons'}
                                                       id={"shareButtonLock"}
                                                       data-intercom-target="shareButtonLock"
                                                       onClick={() => changeShareShow(true)} data-tip="Share"
                                                       data-place="bottom" data-delay-show="700">
                                        <FontAwesomeIcon className={'iconBtn'} icon={faShare} />
                                        <span>     <FormattedMessage {...messages.main_menu_button_share}></FormattedMessage></span>
                                    </GlobalButtonFinal>
                                    : null}

                            </div>

                            :
                            <>
                            {authData.userDeveloper ?

                                        <Link href={"/packshots"}>
                                            <GlobalButtonFinal className={sideMenuPage === "packshots" ? "active btn-circle borderGray menuButtons packshotButton" : " btn-circle borderGray menuButtons packshotButton"}
                                                               style={{marginTop:"9px", border:"unset!important"}}
                                                               data-tip="Packshots" data-place="bottom" data-delay-show="700">
                                                <FontAwesomeIcon className={"iconBtn"} icon={faCamera}/>
                                                <span>  <FormattedMessage {...messages.main_menu_button_render_packshot}></FormattedMessage></span>
                                            </GlobalButtonFinal>
                                        </Link>
                                    : null}
                            </>
                        }
                        <ul className={`clearlist mainMenuUl 
                        ${allNotifications.length > 0 ? 'withNotification' : ''}`}>


                            {enableNotifications &&!typeShareView
                                ?

                                <li
                                    key={"notificationOpenButton"}
                                    onClick={() => changeMenuTypeOpened('notification')}
                                    className={`notificationBell buttonMenuMain ${showNotifications ? 'active' : ''} `}>
                                    <FontAwesomeIcon className={`notificationBellIcon `} icon={faBell} />

                                    <span className={newNotificationsCount > 0 ? 'alertNumber' : "alertNumber opacityHide"}>{newNotificationsCount}</span>

                                    {allNotifications.length === 0 ?
                                        <ul className={` mn-sub subVersions notificationMenu ${showNotifications ? 'show' : ''}`}>

                                            <li className={'notification'} key={"noifictaionIcon"}>

                                            <div className={'notificationIcon'}>
                                             {/*   <FontAwesomeIcon icon={faUsers} />*/}
                                            </div>
                                            <div className={'notificationDesc'} style={{width:"100%"}}>
                                                <h6  style={{textAlign:"center"}}> <FormattedMessage {...messages.main_menu_no_notification_title}></FormattedMessage></h6>

                                            </div>
                                        </li>


                                        </ul>
                                        :
                                        <ul className={` mn-sub subVersions notificationMenu ${showNotifications ? 'show' : ''}`}>


                                            {allNotifications.map((value, index) => {

                                                        if( (value.type.toUpperCase() === 'TEAM_INVITATION' && value.subject.teamInvitation) || value.type.toUpperCase() !== 'TEAM_INVITATION' ) {

                                                            return <li className={'notification'} key={value+index+"notif"+Math.random()}>

                                                                <div className={'notificationIcon'}>
                                                                    <FontAwesomeIcon icon={faUsers} style={{marginTop:"5px"}} />
                                                                </div>
                                                                <div className={'notificationDesc'} style={{paddingBottom:"10px"}}>

                                                                    {value.type.toUpperCase() === 'TEAM_INVITATION_ACCEPTED' ?
                                                                        <>
                                                                            {language === 'cs' ?
                                                                                <>
                                                                                    <h6 style={{ paddingBottom: '5px' }}>  {value.subject.user.full_name + ' přijal Vaši pozvánku do teamu'}</h6>
                                                                                </>
                                                                                : <>
                                                                                <h6 style={{ paddingBottom: '5px' }}>  {value.subject.user.full_name + " accept your team invitation"}</h6>
                                                                                </>}


                                                                            <span>{new Date(value.createdAt).toLocaleString()}</span>
                                                                        </>
                                                                        : null  }

                                                                    {value.type.toUpperCase() === 'SHARE_INVITATION' ?
                                                                        <div
                                                                            onClick={() => window.open(value.subject.share.shareResource.type === 'product_version' ? '/editor/' + value.subject.share.shareResource.uuid
                                                                                : '/products/' + value.subject.share.shareResource.uuid, '_self', 'noreferrer')}
                                                                            style={{ padding: '0' }}>

                                                                            {language === 'cs' ?
                                                                                <>
                                                                                    {value.subject.share.shareResource.type === 'product_version' ?
                                                                                        <h6>{value.createdBy.full_name + ' s Vámi sdílí novou verzi produktu ! ' + value.subject.share.shareResource.name}</h6>
                                                                                        :
                                                                                        <h6>{value.createdBy.full_name + ' s Vámi sdílí produkt ! ' + value.subject.share.shareResource.name}</h6>}
                                                                                </>
                                                                                : <>
                                                                                    {value.subject.share.shareResource.type === 'product_version' ?
                                                                                        <h6>{value.createdBy.full_name + ' shared product a version with you ' + value.subject.share.shareResource.name}</h6>
                                                                                        :
                                                                                        <h6>{value.createdBy.full_name + ' shared an product with you ' + value.subject.share.shareResource.name}</h6>}
                                                                                </>}

                                                                            <span>{new Date(value.createdAt).toLocaleString()}</span>

                                                                        </div>
                                                                        : null}

                                                                    {value.type.toUpperCase() === 'RENDER_JOB_FAILED' ?

                                                                            <div
                                                                                style={{ padding: '0' }}>

                                                                                {language === 'cs' ?
                                                                                    <>
                                                                                        <h6 style={{color:"red"}}>{'Tvorba packshotu '+value.subject.render_type + " " +value.subject.quality + ' - ' + value.subject.product_name + " - Verze " + value.subject.product_version_version + " od " + value.subject.created.user.full_name + " nebyla úspěšná. Kontaktujte prosím podporu."}</h6>
                                                                                    </>
                                                                                    : <>
                                                                                        <h6 style={{color:"red"}}>{'Creation of packshot ' + value.subject.render_type + ' ' + value.subject.quality + ' - ' + value.subject.product_name + ' - Version ' + value.subject.product_version_version + ' from ' + value.subject.created.user.full_name + ' was not successful, please contact support.'}</h6>
                                                                                    </>}

                                                                                <span>{new Date(value.createdAt).toLocaleString()}</span>

                                                                            </div>

                                                                        : null}

                                                                    {value.type.toUpperCase() === 'RENDER_JOB_COMPLETED' ?

                                                                            <div
                                                                                onClick={()=>downloadPackshot(value.subject.download_path)}
                                                                                style={{ padding: '0' }}>

                                                                                {language === 'cs' ?
                                                                                    <>
                                                                                        <h6>{value.subject.created.user.full_name + ' vytvořil nový '+value.subject.render_type + " " +value.subject.quality + ' resolution packshot pro produkt ' + value.subject.product_name + " - Verze " + value.subject.product_version_version}</h6>
                                                                                    </>
                                                                                    : <>
                                                                                        <h6>{value.subject.created.user.full_name + ' created new '+value.subject.render_type + " " +value.subject.quality + ' resolution packshot for product ' + value.subject.product_name + " - Version " + value.subject.product_version_version}</h6>
                                                                                    </>}

                                                                                <span>{new Date(value.createdAt).toLocaleString()}</span>

                                                                            </div>

                                                                        : null}

                                                                    {value.type.toUpperCase() === 'NEW_CORRECTION' ?
                                                                        <div
                                                                            onClick={() => window.open('/editor/' + value.subject.product_version.uuid, '_self')}
                                                                            style={{ padding: '0' }}>

                                                                            {language === 'cs' ?
                                                                                <>
                                                                                    <h6>{value.createdBy.full_name + ' přidal novou korekturu' + ' k produktu ' + value.subject.product_version.name}</h6>
                                                                                </>
                                                                                : <>
                                                                                    <h6>{value.createdBy.full_name + ' added a new correction for the product ' + value.subject.product_version.name}</h6>
                                                                                </>}

                                                                            <span>{new Date(value.createdAt).toLocaleString()}</span>

                                                                        </div>
                                                                        : null}
                                                                    {value.type.toUpperCase() === 'NEW_CORRECTION_COMMENT' ?
                                                                        <div
                                                                            onClick={() => window.open("/editor/"+value.subject.product_version.uuid, "_self")}
                                                                            style={{ padding: '0' }}>

                                                                            {language === 'cs' ?
                                                                                <>
                                                                                    <h6>{value.createdBy.full_name + ' přidal nový komentář ' +  ' ke korektuře u produktu ' + value.subject.correction.product_version.name}</h6>
                                                                                </>
                                                                                : <>
                                                                                    <h6>{value.createdBy.full_name + ' added a new comment to the correction of the product ' + value.subject.correction.product_version.name}</h6>
                                                                                </>}

                                                                            <span>{new Date(value.createdAt).toLocaleString()}</span>

                                                                        </div>
                                                                        : null}

                                                                    {value.type.toUpperCase() === 'TEAM_INVITATION' && value.subject.teamInvitation ?
                                                                        <>
                                                                            <h6 style={{ paddingBottom: '5px' }}>
                                                                                {value.message} {value.subject.teamInvitation ? value.subject.teamInvitation.team.name : null}</h6>

                                                                            <div className={'notificationsButton'}>
                                                                                <GlobalButtonFinal
                                                                                    onClick={() => acceptInvite(value.subject.teamInvitation.uuid, value.subject.teamInvitation.team.uuid)}
                                                                                    style={{
                                                                                        width: '48%',
                                                                                        paddingLeft: '10px',
                                                                                        paddingRight: '10px',
                                                                                    }}
                                                                                    className={'backgroundBlue btn-circle'}><FormattedMessage {...messages.main_menu_notification_button_accept}></FormattedMessage></GlobalButtonFinal>
                                                                                <GlobalButtonFinal
                                                                                    onClick={() => declineInvite(value.uuid)}
                                                                                    style={{
                                                                                        width: '48%',
                                                                                        marginLeft: '5px',
                                                                                        paddingLeft: '10px',
                                                                                        paddingRight: '10px',
                                                                                    }}
                                                                                    className={'borderRed btn-circle'}><FormattedMessage {...messages.main_menu_notification_button_reject}></FormattedMessage></GlobalButtonFinal>
                                                                            </div>

                                                                            <span>{new Date(value.createdAt).toLocaleString()}</span>
                                                                        </>
                                                                        : null}

                                                                </div>
                                                            </li>;
                                                        }

                                            })}


                                        </ul>
                                    }


                                </li>
                                : null}

                            {!typeShareView ?



                                <li
                                    key={"menuOpenButton"}  id={"menuOpenButton"}

                                    className={showUserMenu ? 'buttonMenuMain mainMenuButton active' : 'buttonMenuMain mainMenuButton'}
                                    // onClick={() => changeMenuTypeOpened("menu")}
                                    style={sideMenuPage !== 'team-select'
                                        ? { marginLeft: '5px', marginTop: '1px' } : { marginLeft: '5px' }}
                                    onClick={() => changeMenuTypeOpened("menu")}
                                >

                                    <FontAwesomeIcon className={'iconBtn faMenuBars'} icon={faBars} />

                                    <span style={{ marginLeft: '8px' }}><FormattedMessage {...messages.main_menu_title}></FormattedMessage></span>

                                    <ul className={` mn-sub ${showUserMenu ? 'show' : ''}  ${authData.appExternal ? 'appExternal' : ''} menuInside  ${sideMenuPage === 'team-select' ? 'teamSelect' : ''}`}>

                                        <span
                                            className={'dividerText'}><FormattedMessage {...messages.main_menu_title_me}></FormattedMessage></span>

                                        <li
                                            key={'userInfoMenu'} id={'userInfoMenu'}
                                            className={'userInfo'} >
                                            <div onClick={() => window.open('/profile', '_self')}>
                                                <div style={{
                                                    textAlign: 'center',
                                                    lineHeight: '6',
                                                    width: '30%',
                                                    display: 'inline-block',
                                                    float: 'left',
                                                }}>
                                                    <img src={authData.userAvatar} />
                                                </div>

                                                <div
                                                    onClick={() => window.open('/profile', '_self')}
                                                    style={{
                                                        width: '60%',
                                                        display: 'inline-block',
                                                        float: 'left',
                                                    }}>
                                                    <h5 style={{
                                                        marginBottom: '0',
                                                    }}>{authData.userName.length > 11 ? authData.userName.substring(0, 11) + '...' : authData.userName}</h5>
                                                    <span>{authData.userEmail.length > 16 ? authData.userEmail.substring(0, 16) + '...' : authData.userEmail}</span>


                                                </div>
                                            </div>
                                            {sideMenuPage !== 'team-select' ?
                                                <li key={'myAccount'}>
                                                    <Link href="/profile"><FontAwesomeIcon style={{
                                                        height: '1.2em',
                                                        marginRight: language === "cs" ? "9px" : '7px',

                                                        filter: 'none !important',
                                                    }}
                                                                                           icon={faUser} /><FormattedMessage {...messages.main_menu_items_my_account}></FormattedMessage>
                                                    </Link>
                                                </li>
                                                : null}


                                            <li key={'signout'}
                                                onClick={typePlugin ? () => signOutPlugin() : () => signOutFunction()}>
                                                <a className={'signOutButton'}>
                                                    <div href=""><FontAwesomeIcon style={{
                                                        height: '1.2em',
                                                        marginRight: '8px',

                                                        filter: 'none !important',
                                                    }} icon={faRightFromBracket} />
                                                        <FormattedMessage {...messages.main_menu_items_sign_out}></FormattedMessage>
                                                    </div>
                                                </a></li>

                                        </li>

                                        {sideMenuPage !== 'team-select' && !typePlugin
                                            ?
                                            <>

                                                <li style={{ marginTop: '10px' }} key={'team_title_menu'}><span
                                                    className={'dividerText team'}> <FormattedMessage {...messages.main_menu_title_team}></FormattedMessage> </span>
                                                </li>

                                                <li className={'teamInfo'}
                                                    key={'teamInfo'}>
                                                    <div onClick={() => window.open('/team', '_self')} style={{
                                                        textAlign: 'center',
                                                        lineHeight: '6',
                                                        width: '30%',
                                                        display: 'inline-block',
                                                        float: 'left',
                                                    }}>
                                                        <img src={authData.teamAvatar ? authData.teamAvatar : '/images/logo.svg'} />
                                                    </div>
                                                    <div style={{
                                                        width: '60%',
                                                        display: 'inline-block',
                                                        float: 'left',
                                                    }}>
                                                        {Object.keys(authData.userTeams).map(key => {
                                                            if (getCookie('userTeamUUID') === authData.userTeams[key]['uuid']) {
                                                                return <h5 onClick={() => window.open('/team', '_self')}
                                                                           style={{ marginBottom: '5px' }}
                                                                           key={authData.userTeams[key]['uuid']+Math.random()}> {authData.userTeams[key]['name'].length > 13 ? authData.userTeams[key]['name'].substring(0, 13) + '...' : authData.userTeams[key]['name']}</h5>;
                                                            }
                                                        })}


                                                        {!authData.appExternal ?
                                                            <>
                                                                <Link href={'/subscription'} className={'planLink'}
                                                                      style={{ marginTop: '5px', display:"inline-block", width:"fit-content" }}>
                                                            <span className={'plan'} style={{ paddingTop: '1px' }}>
                                                                {authData.teamPlan}

                                                            </span>
                                                                </Link>

                                                                <Link href={'/payments-credits'} className={'planLink'} style={{width:"fit-content", display:"inline-block"}}>
                                                                <span className={'credits'} style={{    marginLeft:"10px", width: "fit-content", display: "inline-block"}}>
                                                                {authData.teamCredits}&nbsp;

                                                                  <svg xmlns="http://www.w3.org/2000/svg" width="14"
                                                                       height="7"
                                                                       style={{ marginLeft:"-3px", marginTop: '-5px' }}
                                                                       viewBox="0 0 14 14" fill="none">
                                                                    <path
                                                                        d="M4.87751 7.87231L0.0528927 7.87231C0.483663 11.3239 3.42905 13.9946 6.99904 13.9946C7.96289 13.9946 8.87828 13.8008 9.71289 13.4508L7.30058 9.27231C6.78366 8.37847 5.90597 7.87231 4.87751 7.87231Z"
                                                                        fill="#ffffff" />
                                                                    <path
                                                                        d="M7.30058 4.72231L9.71289 0.543846C8.87828 0.193846 7.96289 0 6.99904 0C3.42905 0 0.483663 2.67077 0.0528927 6.12231L4.87751 6.12231C5.90597 6.12231 6.78905 5.61615 7.30058 4.72231Z"
                                                                        fill="#ffffff" />
                                                                </svg>
                                                                     <FormattedMessage {...messages.main_menu_items_credits}></FormattedMessage>

                                                            </span>
                                                            </Link>
                                                            </>
                                                            : <span className={'plan'}
                                                                    style={{ top: '5px', position: 'relative' }}>
                                                                <FormattedMessage {...messages.main_menu_items_external_plan}></FormattedMessage>
                                                            </span>}

                                                    </div>

                                                </li>

                                                {!authData.appExternal ?

                                                    <>
                                                        {/* <li key={'payments-credits'} className={'paymentsCredits'}><Link href="/payments-credits">*/}
                                                        {/*    <div href=""><FontAwesomeIcon style={{*/}
                                                        {/*        height: '1.2em',*/}
                                                        {/*        marginRight: '12px',*/}
                                                        {/*        filter: 'none !important',*/}
                                                        {/*    }} icon={faCoins} />*/}
                                                        {/*        <FormattedMessage {...messages.main_menu_items_purchase_credits}></FormattedMessage>*/}
                                                        {/*    </div>*/}
                                                        {/*</Link></li>*/}
                                                        <li key={'upgrade'} className={'upgrade'}><Link href="/subscription">
                                                            <div href=""><FontAwesomeIcon style={{
                                                                height: '1.2em',
                                                                marginRight: '12px',

                                                                filter: 'none !important',
                                                            }}
                                                                                          icon={faUp} /><FormattedMessage {...messages.main_menu_items_upgrade}></FormattedMessage>
                                                            </div>
                                                        </Link></li>
                                                    </> : null}

                                                <li
                                                    key={'switchteams'}
                                                    className={'switchTeam'}
                                                    onClick={() => handleClickSwitchTeams()}

                                                ><a className={` ${showSwitchTeams ? 'active' : ''}`}>
                                                    <FontAwesomeIcon style={{
                                                        height: '1.2em',
                                                        marginRight: '12px',

                                                        filter: 'none !important',
                                                    }} icon={faArrowRightArrowLeft} />
                                                    <FormattedMessage {...messages.main_menu_items_switch_teams}></FormattedMessage></a>

                                                </li>

                                            </>
                                            : null}

                                        <li className={'help'}
                                            key={'help_menu'}
                                        ><span
                                            className={'dividerText team'}> <FormattedMessage {...messages.main_menu_title_help}></FormattedMessage> </span>
                                        </li>

                                        {authData.userDeveloper ?
                                            <li key={'developer'}><Link href="/ui-kit-3">
                                                <div href="">UIKit</div>
                                            </Link></li>
                                            : null}


                                        {/*<li key={'help'}><Link href="/help">*/}
                                        {/*    <div href=""><FontAwesomeIcon style={{*/}
                                        {/*        height: '1.2em',*/}
                                        {/*        marginRight: '10px',*/}
                                        {/*        */}
                                        {/*        filter: 'none !important',*/}
                                        {/*    }} icon={faCircleQuestion} />Help*/}
                                        {/*    </div>*/}
                                        {/*</Link></li>*/}

                                        <li key={'faq'}
                                            //onClick={() => window.Intercom('show')}
                                        >
                                            <Link href={'/help'}>
                                                <div><FontAwesomeIcon style={{
                                                    height: '1.2em',
                                                    marginRight: '10px',

                                                    filter: 'none !important',
                                                }} icon={faQuestionCircle} />
                                                    <FormattedMessage {...messages.main_menu_items_faq_help}></FormattedMessage>
                                                </div>
                                            </Link>
                                        </li>

                                        <li key={'contact-support'}
                                            onClick={() => window.Intercom('show')}
                                        >
                                            <a>
                                                <div><FontAwesomeIcon style={{
                                                    height: '1.2em',
                                                    marginRight: '10px',

                                                    filter: 'none !important',
                                                }} icon={faComments} />
                                                    <FormattedMessage {...messages.main_menu_items_contact_support}></FormattedMessage>
                                                </div>
                                            </a>

                                        </li>


                                        <li key={'lang-switch'}
                                            className={'lang_switch'}

                                        >
                                            <a key={'lang-switch-a'}>
                                                <div key={'lang-switch-div'}><FontAwesomeIcon style={{
                                                    height: '1.2em',
                                                    marginRight: '10px',

                                                    filter: 'none !important',
                                                }} icon={faLanguage} />


                                                    {Object.keys(allLanguages).map(key => {
                                                        if (allLanguages[key].code === 'cs') {
                                                            return <span
                                                                key={allLanguages[key].code}
                                                                className={language === allLanguages[key].code ? 'active' : ''}
                                                                onClick={() => setLanguageFunction(allLanguages[key].uuid)}>{allLanguages[key].name} </span>;
                                                        }

                                                    })}


                                                    &nbsp;/&nbsp;
                                                    {Object.keys(allLanguages).map(key => {
                                                        if (allLanguages[key].code === 'en') {
                                                            return <span
                                                                key={'lang-switch' + allLanguages[key].code}
                                                                className={language === allLanguages[key].code ? 'active' : ''}
                                                                onClick={() => setLanguageFunction(allLanguages[key].uuid)}>{allLanguages[key].name} </span>;
                                                        }

                                                    })}
                                                </div>
                                            </a>
                                        </li>


                                        {sideMenuPage !== 'team-select' && typePlugin
                                            ?
                                            <>
                                                <li key={'userInfo'} className={'userInfo'}>
                                                    <div style={{
                                                        textAlign: 'center',
                                                        lineHeight: '6',
                                                        width: '30%',
                                                        display: 'inline-block',
                                                        float: 'left',
                                                    }}>
                                                        <img src={authData.userAvatar} style={{ height: '30px' }} />
                                                    </div>
                                                    <div style={{
                                                        width: '60%',
                                                        display: 'inline-block',
                                                        float: 'left',
                                                    }}>
                                                        <h5>{authData.userName}</h5>
                                                        <span><em>{authData.userEmail}</em></span>
                                                        <p>
                                                            {Object.keys(authData.userTeams).map(key => {
                                                                return (<>
                                                                    {getCookie('userTeamUUID') === authData.userTeams[key]['uuid'] ?
                                                                        <span> {authData.userTeams[key]['name']}</span>
                                                                        :
                                                                        null}
                                                                </>);

                                                            })}
                                                        </p>


                                                        <p className={'plan'}>
                                                            {authData.appExternal ?
                                                                <FormattedMessage {...messages.main_menu_items_basic_plan}></FormattedMessage>
                                                                :
                                                                <FormattedMessage {...messages.main_menu_items_external_plan}></FormattedMessage>}

                                                        </p>
                                                    </div>
                                                    <div style={{ width: '100%' }}>
                                                        <hr />
                                                    </div>

                                                </li>

                                            </>
                                            : null}


                                    </ul>

                                </li>

                                : null}


                        </ul>
                    </div>

                </div>
            </nav>

            <DialogLockVersion
                productLocked={versionIsLocked}
                masterProductID={masterProductUUID}
                productID={versionProductUUID}
                setVersionIsLocked={setVersionIsLocked}
                toggleLockVersion={(toggleLockVersion: () => void) => {
                    handleLockVersion = toggleLockVersion;
                }}
            />


            <ModalsWrapper
                className={`  ${showLockDialogTransition ? 'showTransition' : 'hideTransition'} ${!showLockDialog ? 'hiddeComplete' : ''}`}>


                <ModalWindow id={'lockProductDialog'} style={{ minHeight: 'unset', height: '170px' }}
                             className={'center'}>
                    <div className={'modal-header'}>
                        <h4> <FormattedMessage {...messages.modal_version_lock_title}></FormattedMessage></h4>
                    </div>


                    <div className={'modal-content shareProduct'} style={{ display: 'block' }}>
                        {versionIsLocked ?
                            <span>    <FormattedMessage {...messages.modal_version_unlock_title}></FormattedMessage>  </span>
                            :
                            <span> <FormattedMessage {...messages.modal_version_lock_title}></FormattedMessage> </span>}


                        <div className={'modal-buttons'}>

                            <GlobalButtonFinal style={{ width: '48%', float: 'left' }}
                                               className={'backgroundBlue btn-circle '} data-uuid={'productID'}
                                               onClick={() => lockProductVersion(masterProductUUID, versionProductUUID)}>

                                {versionIsLocked ?
                                    <div><FontAwesomeIcon icon={faUnlock} /> <FormattedMessage {...messages.modal_version_unlock_button_unlock_version}></FormattedMessage> </div>
                                    :
                                    <div><FontAwesomeIcon icon={faLock} /> <FormattedMessage {...messages.modal_version_lock_button_lock_version}></FormattedMessage></div>}


                            </GlobalButtonFinal>
                            <GlobalButtonFinal style={{ width: '48%', marginLeft: '15px', float: 'left' }}
                                               className={'borderBlue btn-circle'}
                                               onClick={() => changeLockShow(false)}>
                                <FormattedMessage {...messages.modal_version_lock_button_cancel}></FormattedMessage>
                            </GlobalButtonFinal>
                        </div>

                    </div>
                </ModalWindow>


            </ModalsWrapper>

            <DialogDuplicateVersion
                triggerWarning={triggerWarning}
                masterProductUUID={masterProductUUID}
                toggleDuplicateVersion={(toggleDuplicateVersion: () => void) => {
                    handleClickDuplicateVersion = toggleDuplicateVersion;
                } }
                versionProductUUID={versionProductUUID}
            />






            <DialogCreateNewTeam
                switchTeam={switchTeam}
                toggleCreateNewTeam={(toggleCreateNewTeam: () => void) => {
                    handleClickCreateNewTeam = toggleCreateNewTeam;
                }}
            />


            {typeEditor && !typeShareView && !authData.appExternal ?
                <ShareDialog
                    slugState={slugState}
                    triggerWarning={triggerWarning}
                    typeEditor={typeEditor}
                    showShareDialog={showShareDialog}
                    showShareDialogTransition={showShareDialogTransition}
                    changeShareShow={changeShareShow} />
                : null}



        </MainMenuHeaderNew>

            <DialogAlert
                toggleAlert={(toggleAlert: () => void) => {
                    handleTriggerWarning = toggleAlert;
                }}
                alertType={warningType}
                message={warningMessage}
            />


            <DialogSwitchTeams
                loadUserInfoAPI={refreshAuthInfo}
                toggleSwitchTeams={ (toggleSwitchTeams: () => void) => {
                    handleClickSwitchTeams = toggleSwitchTeams;
                } }
                typeEditor={typeEditor}
                userTeams={authData.userTeams}

            />
        </>
    );
}